
<!-- 统一应用首页 -->
<template>
  <div class="imgbjdv">
    <div class="pptTit">
      <div class="pptTitspinner" style="margin-top:8px;margin-right:24px;">
        <div class="pptTitloader pptTitl1" />
        <div class="pptTitloader pptTitl2" />
      </div>
      <span class="pptTitSpan">数治教育产品矩阵</span>
      <div class="pptTitspinner" style="margin-top:16px;margin-left:12px;">
        <div class="pptTitloader pptTitl1" />
        <div class="pptTitloader pptTitl2" />
      </div>
    </div>

    <div class="tcbtndv">
      <button class="tcbtn" @click="openPptTc">
        <a>能力介绍</a>
      </button>
    </div>

    <div class="cardli">
      <div class="book booka">
        <div style="margin-left: 85px;">
          <button class="cta" @click="btnAa">
            <span>产品介绍</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <button class="cta" @click="btnAb">
            <span>产品大屏</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <button class="cta" @click="btnAc">
            <span>产品演示</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
        </div>
        <div class="cover">
          <div class="coverTopTit">
            <img :src="require('@/assets/images/home/ppttopa.png')" class="coverTopTitImg">
            <em>办事专窗</em>
          </div>
          <p>数治教育云平台</p>
          <div class="loader">
            <svg viewBox="0 0 80 80">
              <circle id="test" cx="40" cy="40" r="32" />
            </svg>
          </div>
        </div>
      </div>
      <div class="book">
        <div style="margin-left: 85px;">
          <button class="cta" @click="btnBa">
            <span>产品介绍</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <button class="cta" @click="btnBb">
            <span>产品大屏</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <button class="cta" @click="btnBc">
            <span>产品演示</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
        </div>
        <div class="cover">
          <div class="coverTopTit">
            <img :src="require('@/assets/images/home/ppttopb.png')" class="coverTopTitImg">
            <em>数据专窗</em>
          </div>
          <p>数据治理平台</p>
          <div class="loader triangle">
            <svg viewBox="0 0 86 80">
              <polygon points="43 8 79 72 7 72" />
            </svg>
          </div>
        </div>
      </div>

      <div class="book booka">
        <div style="margin-left: 85px;">
          <button class="cta">
            <span>产品介绍</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <button class="cta" @click="btnCb">
            <span>产品大屏</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <button class="cta" @click="btnCc">
            <span>产品演示</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
        </div>
        <div class="cover">
          <div class="coverTopTit">
            <img :src="require('@/assets/images/home/ppttopc.png')" class="coverTopTitImg">
            <em>综合窗口</em>
          </div>
          <p>教育大脑</p>
          <div class="bulb" style="margin-top:8px;">
            <svg width="60pt" height="60pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path d="m930 552.86h-660c-6.9297 0-13.191-4.168-15.836-10.582-2.6602-6.4102-1.1875-13.777 3.7148-18.684l289.72-289.7c4.1367-4.1367 10.078-5.875 15.836-4.6211 5.707 1.2734 10.379 5.375 12.387 10.883 3.7031 10.164 13.43 16.996 24.176 16.996s20.473-6.832 24.191-17.008c2.0078-5.5078 6.6797-9.5938 12.406-10.863 5.6602-1.2539 11.668 0.48437 15.82 4.6211l289.7 289.7c4.9062 4.9062 6.3789 12.27 3.7148 18.684-2.6445 6.4062-8.9062 10.574-15.836 10.574zm-618.62-34.285h577.23l-245.5-245.51c-11.102 11.469-26.586 18.367-43.109 18.367-16.539 0-32.008-6.8984-43.109-18.348z" />
                <path d="m986.48 1028.6h-772.97c-23.203 0-42.086-18.883-42.086-42.086v-425.82c0-23.203 18.883-42.09 42.086-42.09h772.97c23.203 0 42.086 18.887 42.086 42.09v425.82c0 23.203-18.883 42.086-42.086 42.086zm-772.97-475.71c-4.3008 0-7.8008 3.5-7.8008 7.8047v425.82c0 4.3008 3.5 7.8008 7.8008 7.8008h772.97c4.3008 0 7.8008-3.5 7.8008-7.8008v-425.82c0-4.3047-3.5-7.8047-7.8008-7.8047z" />
                <path d="m599.92 935.74c-89.332 0-162-72.758-162-162.17 0-89.414 72.672-162.17 162-162.17 89.414 0 162.17 72.758 162.17 162.17-0.003906 89.414-72.758 162.17-162.17 162.17zm0-290.05c-70.43 0-127.72 57.371-127.72 127.89 0 70.512 57.289 127.89 127.72 127.89 70.512 0 127.89-57.371 127.89-127.89-0.003907-70.516-57.375-127.89-127.89-127.89z" />
                <path d="m576.71 838.07c-4.5547 0-8.9062-1.8086-12.121-5.0234l-48.113-48.129c-6.6953-6.6953-6.6953-17.543 0-24.242 6.6953-6.6953 17.543-6.6953 24.242 0l35.992 36.012 82.582-82.582c6.6953-6.6953 17.543-6.6953 24.242 0 6.6953 6.6953 6.6953 17.543 0 24.242l-94.703 94.703c-3.2148 3.2109-7.5703 5.0195-12.121 5.0195z" />
                <path d="m600 291.43c-25.113 0-47.777-15.887-56.383-39.559-2.3633-6.1445-3.6172-13.125-3.6172-20.441 0-33.082 26.918-60 60-60s60 26.918 60 60c0 7.3477-1.2383 14.312-3.6992 20.727-8.5391 23.387-31.207 39.273-56.301 39.273zm0-85.715c-14.18 0-25.715 11.535-25.715 25.715 0 3.0625 0.50391 5.9766 1.4414 8.4375 3.8008 10.445 13.527 17.277 24.273 17.277s20.473-6.832 24.191-17.008c1.0391-2.6992 1.5234-5.6094 1.5234-8.707 0-14.18-11.535-25.715-25.715-25.715z" />
              </g>
            </svg>
          </div>
        </div>
      </div>

      <div class="book">
        <div style="margin-left: 65px;">
          <button class="cta">
            <span>产品介绍</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <button class="cta" @click="btnDb">
            <span>产品演示<em style="font-size:10px;color: #999;">(开发)</em></span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <button class="cta" @click="btnDc">
            <span>产品演示<em style="font-size:10px;color: #999;">(运营)</em></span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
        </div>
        <div class="cover">
          <div class="coverTopTit">
            <img :src="require('@/assets/images/home/ppttopd.png')" class="coverTopTitImg">
            <em>服务专窗</em>
          </div>
          <p>服务开放平台</p>
          <div class="loader">
            <svg viewBox="0 0 80 80">
              <rect x="8" y="8" width="64" height="64" />
            </svg>
          </div>
        </div>
      </div>

      <div class="book booka">
        <div style="margin-left: 85px;">
          <button class="cta">
            <span>产品介绍</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <button class="cta" @click="btnEb">
            <span>产品大屏</span>
            <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg>
          </button>
          <!-- <button class="cta" @click="btnEc"> -->
          <button class="cta">
            <span style="color: #999;cursor: default;">产品演示</span>
            <!-- <svg viewBox="0 0 13 10" height="10px" width="15px">
              <path d="M1,5 L11,5" />
              <polyline points="8 1 12 5 8 9" />
            </svg> -->
          </button>
        </div>
        <div class="cover">
          <div class="coverTopTit">
            <img :src="require('@/assets/images/home/ppttope.png')" class="coverTopTitImg">
            <em>高校专窗</em>
          </div>
          <p>高校管理驾驶舱</p>
          <div class="loader">
            <svg viewBox="0 0 80 80">
              <circle id="test" cx="40" cy="40" r="32" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!-- <div style="display:flex; align-items: center; justify-content: center;width: 100%; margin-top: 6%;">
      <div style="width: 1000px; margin: auto;">
        <el-carousel :interval="4000" type="card" height="350px" :autoplay="true">
          <el-carousel-item v-for="item in 6" :key="item">
            <div class="book">
              <div style="margin-left: 85px;">
                <button class="cta" @click="btnAa">
                  <span>产品介绍</span>
                  <svg viewBox="0 0 13 10" height="10px" width="15px">
                    <path d="M1,5 L11,5" />
                    <polyline points="8 1 12 5 8 9" />
                  </svg>
                </button>
                <button class="cta" @click="btnAb">
                  <span>产品大屏</span>
                  <svg viewBox="0 0 13 10" height="10px" width="15px">
                    <path d="M1,5 L11,5" />
                    <polyline points="8 1 12 5 8 9" />
                  </svg>
                </button>
                <button class="cta" @click="btnAc">
                  <span>产品演示</span>
                  <svg viewBox="0 0 13 10" height="10px" width="15px">
                    <path d="M1,5 L11,5" />
                    <polyline points="8 1 12 5 8 9" />
                  </svg>
                </button>
              </div>
              <div class="cover">
                <div class="coverTopTit">
                  <img :src="require('@/assets/images/home/ppttopa.png')" class="coverTopTitImg">
                  <em>办事专窗</em>
                </div>
                <p>数治教育云平台{{ item }}</p>
                <div class="loader">
                  <svg viewBox="0 0 80 80">
                    <circle id="test" cx="40" cy="40" r="32" />
                  </svg>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->

    <el-dialog
      v-model="pptShow"
      title=""
      width="80%"
      top="1%"
    >
      <div style="width: 100%;">
        <el-carousel height="800px" :autoplay="false">
          <el-carousel-item>
            <img :src="require('@/assets/images/home/pptaa.jpg')" class="tcimg">
          </el-carousel-item>
          <el-carousel-item>
            <img :src="require('@/assets/images/home/ppta.jpeg')" class="tcimg">
          </el-carousel-item>
          <el-carousel-item>
            <img :src="require('@/assets/images/home/pptb.jpg')" class="tcimg">
          </el-carousel-item>
          <el-carousel-item>
            <img :src="require('@/assets/images/home/pptc.jpeg')" class="tcimg">
          </el-carousel-item>
          <el-carousel-item>
            <img :src="require('@/assets/images/home/pptd.jpg')" class="tcimg">
          </el-carousel-item>
          <el-carousel-item>
            <img :src="require('@/assets/images/home/ppte.jpeg')" class="tcimg">
          </el-carousel-item>
          <el-carousel-item>
            <img :src="require('@/assets/images/home/pptf.jpeg')" class="tcimg">
          </el-carousel-item>
        </el-carousel>
      </div>
      <template #footer>
        <span class="dialog-footer" />
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import imgListh from '@/assets/images/home/list8.png'
export default defineComponent({
  name: '统一应用',
  components: { },
  emits: [],
  props: {
    // catalogTitle: {
    //   type: String,
    //   default: ''
    // }
  },
  setup() {
    const state = reactive({
      pptShow: false,
      screenHeight: 800

    })
    const methods = reactive({
      openPptTc: () => {
        state.pptShow = true
      },
      btnAa: () => {
        window.open('https://alidocs.dingtalk.com/i/nodes/XPwkYGxZV3R7yzX0tjlKkxbMWAgozOKL?corpId=ding9560db2701bfe72535c2f4657eb6378f&utm_medium=im_card&iframeQuery=utm_medium%3Dim_card%26utm_source%3Dim&utm_scene=team_space&utm_source=im')
      },
      btnAb: () => {
        window.open('http://vividshow.ictsoft.cn/dplus/view/1597152760698847233')
      },
      btnAc: () => {
        window.open('https://smartcampus.ictsoft.cn/saas-portal-campus-new/?domainName=jyzh-campus-new.ictsoft.cn#/login')
      },
      btnBa: () => {
        window.open('https://alidocs.dingtalk.com/i/nodes/93NwLYZXWygXb9lOs3oop1KxJkyEqBQm')
      },
      btnBb: () => {
        window.open('https://vivid.saas.ictsoft.cn/dplus/view/1651054033887010818?note=79635547gi&hasPassword=0')
      },
      btnBc: () => {
        window.open('https://dota-datacloud.ictsoft.cn')
      },
      btnCb: () => {
        window.open('https://vivid.saas.ictsoft.cn/dplus/view/1648147606377832449?note=mv3vxo65y8&hasPassword=0')
      },
      btnCc: () => {
        window.open('https://edu2-datacloud.ictsoft.cn/login?service=https%3A%2F%2Fedu3-datacloud.ictsoft.cn%2Fbackend%2FcasLogin%3FtoUrl%3D%252Fpersonal')
      },
      btnDb: () => {
        window.open('https://platform-dev.ictsoft.cn/')
      },
      btnDc: () => {
        window.open('https://platform-manage.ictsoft.cn/')
      },
      btnEb: () => {
        window.open('https://vivid.saas.ictsoft.cn/dplus/view/1586975482264088577?note=eot30ddnuh&hasPassword=0')
      },
      btnEc: () => {
        window.open('https://vivid.saas.ictsoft.cn/dplus/view/1586975433945706497?note=6gwu59yxmf&hasPassword=0')
      }
    })
    onMounted(() => {
      state.screenHeight = window.screen.height - 0
      console.log(11111, state.screenHeight)
    })
    return {
      ...toRefs(state),
      ...toRefs(methods)
    }
  }
})
</script>
<style lang="scss">
.el-carousel__mask{
  background: none;
}
</style>
<style lang="scss" scoped>

.el-carousel__item .aad {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  // background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: #d3dce6;
}
.bulb svg {
  display: block;
  height: 90px;
  transform-origin: center top;
  animation: swing_31 1.3s ease-in-out infinite alternate;
  stroke: #fff;
  fill: #fff;
  stroke-width: 8px;
}

@keyframes swing_31 {
  0% {
    transform: rotate(18deg);
  }

  100% {
    transform: rotate(-18deg);
  }
}

.loader {
  --path: #fff;
  --dot: #fff;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}

.loader:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect, .loader svg polygon, .loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader.triangle {
  width: 48px;
}

.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}

.el-carousel__arrow{
  background-color: #b1dae7;
}
.el-carousel__arrow:hover{
  background-color: #b1dae7;
}
.el-carousel__arrow--left{
  left: 5%;
}
.el-carousel__arrow--right{
  right: 5%;
}
</style>
<style lang="scss" scoped>
.tcimg{
  display:block;
  height: 100%;
  margin:auto;
}
@keyframes rot {
  0% {
    transform: rotateX(-15deg) translateY(0px);
  }

  50% {
    transform: rotateX(-15deg) translateY(-10px);
  }

  100% {
    transform: rotateX(-15deg) translateY(0px);
  }
}

.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  // background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: #d3dce6;
}

.tcbtndv{
  // width: 90%;
  position: absolute;
  left: 5%;
  top: 125px;
}

.tcbtn {
  background: transparent;
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  padding: 10px 30px;
  perspective: 30rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.308);
  cursor: pointer;
}
.tcbtn:focus {
  outline: none;
}
.tcbtn::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background: linear-gradient(320deg, rgba(0, 140, 255, 0.678), rgba(128, 0, 128, 0.308));
  z-index: 1;
  transition: background 3s;
}

.tcbtn:hover::before {
  animation: rotate 1s;
  transition: all .5s;
}

@keyframes rotate {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.pptTitSpan{
  color: #fff;
  font-size: 54px;
  font-weight: bold;
  letter-spacing: 10px;
}
.pptTit{
  width: 100%;
  position: absolute;
  left: 0;
  top: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pptTitspinner {
 border: 0 solid transparent;
 border-radius: 50%;
 width: 30px;
 height: 30px;
//  position: absolute;
//   left: 25%;
//   top: 25px;
}

.pptTitloader {
 width: inherit;
 height: inherit;
 position: absolute;
}

.pptTitloader::before,
.pptTitloader::after {
 content: '';
 border: 1px solid #fff;
 border-radius: 50%;
 width: inherit;
 height: inherit;
 position: absolute;
 opacity: 1;
}

.pptTitl1::before,
.pptTitl1::after {
 animation: clockwiseZ 2.5s infinite;
}

.pptTitl2::after,
.pptTitl2::before {
 animation: anticlockwiseZ 2.5s infinite;
}

@keyframes clockwiseZ {
 0%, 100% {
  transform: rotateY(0);
 }

 50% {
  transform: rotateY(180deg) skew(-10deg, -5deg);
 }
}

@keyframes anticlockwiseZ {
 0%, 100% {
  transform: rotateX(0);
 }

 50% {
  transform: rotateX(-180deg) skew(10deg, 5deg);
 }
}
.cta {
 position: relative;
 margin: auto;
 padding: 12px 18px;
 transition: all 0.2s ease;
 border: none;
 background: none;
 cursor: pointer;
 margin: 16px auto !important;
}

.cta:before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 display: block;
 border-radius: 50px;
 background: #b1dae7;
 width: 45px;
 height: 45px;
 transition: all 0.3s ease;
}

.cta span {
 position: relative;
 font-family: "Ubuntu", sans-serif;
 font-size: 18px;
 font-weight: 700;
 letter-spacing: 0.05em;
 color: #234567;
}

.cta svg {
 position: relative;
 top: 0;
 margin-left: 10px;
 fill: none;
 stroke-linecap: round;
 stroke-linejoin: round;
 stroke: #234567;
 stroke-width: 2;
 transform: translateX(-5px);
 transition: all 0.3s ease;
}

.cta:hover:before {
 width: 100%;
 background: #b1dae7;
}

.cta:hover svg {
 transform: translateX(0);
}

.cta:active {
 transform: scale(0.95);
}
.cardli{
  width: 90%;
  height: 350px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.book {
  // margin-top: 3%;
  position: relative;
  border-radius: 10px;
  width: 270px;
  height: 350px;
  margin: auto;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  -webkit-transform: preserve-3d;
  -ms-transform: preserve-3d;
  transform: preserve-3d;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #000;
  // background-image: url(../../assets/images/home/pptTcBJ.png);
  // transform-style: preserve-3d;
  // animation: rot 3s infinite ease;
}
.booka{
  transform-style: preserve-3d;
  animation: rot 5s infinite ease;
}
.booka:hover{
  transform-style: preserve-3d;
  animation: rot 0s infinite ease;
}
.book:hover{
  transform-style: preserve-3d;
  animation: rot 0s infinite ease;
}
.coverTopTit{
  position: absolute;
  top: 10px;
  left: 70px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  em{
    font-style: normal;
    font-weight: bold;
    letter-spacing: 4px;
    margin-left: 5px;
    font-size: 20px;
  }
}
.coverTopTitImg{
  width: 22px;
}
.cover {
  top: 0;
  position: absolute;
  // background-color: #142549;
  // background: linear-gradient(320deg, rgba(0, 140, 255, 1), rgba(128, 0, 128, 1));
  background: linear-gradient(to bottom,#37FFF9,#354ED9);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
}

.book:hover .cover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: rotatey(-80deg);
  -ms-transform: rotatey(-80deg);
  transform: rotatey(-80deg);
}

p {
  font-size: 30px;
  font-weight: bolder;
  color: #fff;
}
.imgbjdv{
  display: flex;
  // justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-image: url("../../assets/images/home/imgbj.png");
  background-size: 100% 100%;
}
</style>
