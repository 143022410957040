
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import imgListh from '@/assets/images/home/list8.png'
export default defineComponent({
  name: '统一应用',
  components: { },
  emits: [],
  props: {
    // catalogTitle: {
    //   type: String,
    //   default: ''
    // }
  },
  setup() {
    const state = reactive({
      pptShow: false,
      screenHeight: 800

    })
    const methods = reactive({
      openPptTc: () => {
        state.pptShow = true
      },
      btnAa: () => {
        window.open('https://alidocs.dingtalk.com/i/nodes/XPwkYGxZV3R7yzX0tjlKkxbMWAgozOKL?corpId=ding9560db2701bfe72535c2f4657eb6378f&utm_medium=im_card&iframeQuery=utm_medium%3Dim_card%26utm_source%3Dim&utm_scene=team_space&utm_source=im')
      },
      btnAb: () => {
        window.open('http://vividshow.ictsoft.cn/dplus/view/1597152760698847233')
      },
      btnAc: () => {
        window.open('https://smartcampus.ictsoft.cn/saas-portal-campus-new/?domainName=jyzh-campus-new.ictsoft.cn#/login')
      },
      btnBa: () => {
        window.open('https://alidocs.dingtalk.com/i/nodes/93NwLYZXWygXb9lOs3oop1KxJkyEqBQm')
      },
      btnBb: () => {
        window.open('https://vivid.saas.ictsoft.cn/dplus/view/1651054033887010818?note=79635547gi&hasPassword=0')
      },
      btnBc: () => {
        window.open('https://dota-datacloud.ictsoft.cn')
      },
      btnCb: () => {
        window.open('https://vivid.saas.ictsoft.cn/dplus/view/1648147606377832449?note=mv3vxo65y8&hasPassword=0')
      },
      btnCc: () => {
        window.open('https://edu2-datacloud.ictsoft.cn/login?service=https%3A%2F%2Fedu3-datacloud.ictsoft.cn%2Fbackend%2FcasLogin%3FtoUrl%3D%252Fpersonal')
      },
      btnDb: () => {
        window.open('https://platform-dev.ictsoft.cn/')
      },
      btnDc: () => {
        window.open('https://platform-manage.ictsoft.cn/')
      },
      btnEb: () => {
        window.open('https://vivid.saas.ictsoft.cn/dplus/view/1586975482264088577?note=eot30ddnuh&hasPassword=0')
      },
      btnEc: () => {
        window.open('https://vivid.saas.ictsoft.cn/dplus/view/1586975433945706497?note=6gwu59yxmf&hasPassword=0')
      }
    })
    onMounted(() => {
      state.screenHeight = window.screen.height - 0
      console.log(11111, state.screenHeight)
    })
    return {
      ...toRefs(state),
      ...toRefs(methods)
    }
  }
})
